import type { IGenericPageProps } from '@on3/ui-lib/api/schema/custom-contracts';
import withAuthentication from '@on3/ui-lib/src/hocs/withAuthentication';
import withValidUrl from '@on3/ui-lib/src/hocs/withValidUrl';
import { NoSidebarLayout } from 'components/Layouts/NoSidebarLayout';
import { PageWrapper } from 'components/Layouts/PageWrapper';
import { PianoLogin } from 'components/User/PianoLogin';
import type { GetServerSideProps } from 'next';

const LoginPage = (props: IGenericPageProps) => {
  const canonicalURL = `https://www.on3.com/login/`;

  return (
    <PageWrapper
      {...props}
      alternateBackground={true}
      canonical={canonicalURL}
      hideAds={true}
      title="Login to your account | On3.com"
    >
      <NoSidebarLayout>
        <PianoLogin />
      </NoSidebarLayout>
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const siteData = await withValidUrl.isValid(ctx);
  const userData = await withAuthentication.isAuth(ctx);

  if (!siteData) {
    return {
      notFound: true,
    };
  }

  return { props: { pageType: 'user', siteData, userData: userData || {} } };
};

export default withValidUrl(LoginPage);
